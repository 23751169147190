import React, { useState } from 'react';
import {
    useRouteMatch,
    useParams,
    Switch,
    Route,
} from "react-router-dom";
import useSWR from 'swr'
import { fetcher } from "../shared/helpers"

import Group from './Group'
import Page from './Page'
import Overview from './Overview'

import { List, ListItem } from 'sancho'
import { Avatar, Button, Alert } from 'sancho'
import { Text, Divider, Spinner } from 'sancho'


import {Helmet} from "react-helmet";


function Customer(props) {
    let { customerId } = useParams();
    let match = useRouteMatch();
    const { data, error } = useSWR(`${props.config.API_BASE_URL}/audit/api/customers/${customerId}/`, fetcher)
  
    if (error) return <div style={{margin: '30px 0 0 0'}}><Alert intent="danger" title="Data failed to load. Please try again." /></div>
    if (!data) return <div style={{margin: '30px 0 0 0'}}><Spinner label="Loading..." center /></div>
  
    return <>

        <Helmet>
            <title>{data.partner.page_title}</title>
        </Helmet>
  
        <Switch>
          <Route path={`${match.path}/group/:groupId`}>
            <Group config={props.config}  />
          </Route>
          <Route path={`${match.path}/:pageId`}>
            <Page config={props.config} />
          </Route>
  
  
          <Route>
            <Overview data={data} customerId={data.customerId} />
          </Route>
  
        </Switch>

        {data.partner.show_consultant ? 

        <>
          <Divider />
    
          <Text variant="h4">Do you have any questions?</Text>
          <Text variant="paragraph">Reach out to your consultant.</Text>
    
          <List>
            <ListItem
              interactive={false}
              contentBefore={<Avatar name={data.consultant.name} src={data.consultant.image} />}
              primary={data.consultant.name}
              wrap={false}
              contentAfter={<Button component='a' href={`mailto:${data.consultant.email}`}>Send an email</Button>}
            />
          </List>
        </>

        : null }

        <br /><br /><br />


    </>;
  }
  
  export default Customer