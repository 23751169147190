import React from 'react';
import {
    useRouteMatch,
    useParams,
    Link,
} from "react-router-dom";
import useSWR from 'swr'
import { fetcher } from "../shared/helpers"

import { Text, Divider, Spinner, Button } from 'sancho'
import { Navbar, Toolbar, Alert } from 'sancho'
import { IconArrowLeft } from 'sancho'

import AnnotationBrowser from './AnnotationBrowser'
import Statistics from '../shared/Statistics'
import styles from './Page.module.css';

function Page(props) {
    let match = useRouteMatch();
    let { customerId, pageId } = useParams();
    const { data, error } = useSWR(`${props.config.API_BASE_URL}/audit/api/customers/${customerId}/${pageId}`, fetcher)
   
    if (error) return <div style={{margin: '30px 0 0 0'}}><Alert intent="danger" title="Data failed to load. Please try again." /></div>
    if (!data) return <div style={{margin: '30px 0 0 0'}}><Spinner label="Loading..." center /></div>
  
    return <div>
  
      <Navbar css={{
                  flex: "0 0 auto",
                }}>
        <Toolbar css={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px 0"
                  }}>
         <div>
         <Button variant="ghost" component={Link} to={`/customer/${customerId}`} iconBefore={<IconArrowLeft/>}>
          Back
            </Button>
            
             </div>
         <div>
  
  
  
        </div>
        </Toolbar>
      </Navbar>
  
      <Text variant="display3">{data.title}</Text>
      <Divider />
      <Statistics className={styles.page_statistics}  data={data} />
  
  

      <AnnotationBrowser image={data.image} annotations={data.all_annotations} />
  
  
    </div>
  }

  export default Page