import React from 'react';
// import LogoImage from '../../public/logo.svg'

function Logo(props) {
    return (
        // <img src={process.env.PUBLIC_URL + '/logo-inline.svg'} alt="Unicorn Audit" style={{height: 35}} />
        <img src={props.logo} alt={props.name} style={{height: 35}} />
    )
  }
  
  export default Logo