const compliance_display = (impact) => {switch(impact) {
    case 1: return 'Low';
    case 2: return 'Low';
    case 3: return 'High';
    case 4: return 'High';
    case 5: return 'Excellent';
    default: return 'N/a';
}}


const impact_display = (impact) => {switch(impact) {
    case 1: return 'Low';
    case 2: return 'Medium';
    case 3: return 'High';
    default: return 'N/a';
}}

const fetcher = (...args) => fetch(...args).then(res => res.json())


function Score(props) {
    return <div>{props.score}%</div>
  }
  
  function Impact(props) {
    return <div>{impact_display(props.value)}{props.suffix ? ` ${props.suffix}` : null}</div>
  }
  
  function Compliance(props) {
    return <div>{compliance_display(props.value)}{props.suffix ? ` ${props.suffix}` : null}</div>
  }
  


export { compliance_display, impact_display, fetcher, Score, Impact, Compliance }