import React, { useRef, useEffect } from 'react';
import styles from './AnnotationPreview.module.css';
import AnnotationBullet from '../shared/AnnotationBullet'
import {
    useRouteMatch,
    useParams,
} from "react-router-dom";

import { Text, Divider, Badge } from 'sancho'


function AnnotationPreview(props) {
    let match = useRouteMatch();
    let { annotationId } = useParams();
  
    let annotation = props.annotations.filter(a => {return a.id == annotationId } )[0]

    // let ref = useRef({})

    // useEffect(() => {
    //     if(annotationId) {
    //       ref.current.scrollIntoView({behavior: "smooth"})
    //       console.log('chs', ref)
    //     }
    // }, [annotationId])

    return (
      <div className={styles.wrapper}>
        <Text variant="h4">{annotation.page.title} <Badge>{annotation.page.device}</Badge></Text>
        
        <Divider />

          <div className={styles.bullet_wrapper}>
  
          <AnnotationBullet 
            key={annotation.id} 
            annotation={annotation} 
            open={true}
            openSource="annotation"
               />
  
          <img src={annotation.page.image} className={styles.image} />
  
          </div>
  
      </div>
    )
  
  }

  export default AnnotationPreview