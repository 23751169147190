import React, { useRef, useEffect } from 'react';
import {
    useRouteMatch,
    useParams,
    Link,
} from "react-router-dom";

import { Badge, Text, Layer } from 'sancho'
import { Collapse, useCollapse } from 'sancho'

import { Compliance, Impact } from "../shared/helpers"
import Statistics from "../shared/Statistics"



import styles from './GroupThumbnail.module.css';



function GroupThumbnail(props) {
    let match = useRouteMatch();
  
    return (
        <Link to={`${match.url}/group/${props.group.id}`} className={styles.group}>
          <Layer elevation="sm" className={styles.group_link}>
            <Text variant="uppercase">{props.group.title} </Text>
            <Text variant="h5">{props.group.score}%</Text>
            <Statistics data={{low: props.group.low, high: props.group.high, strengths: props.group.strengths}} compact />
            <Text variant="subtitle">{props.group.num_annotations} annotations</Text>
          </Layer>
        </Link>
    )
  }

  export default GroupThumbnail