import React from 'react';
import './reset.css';
import styles from './App.module.css';

import { Container } from "sancho";
import { generateColorsFromScales, ThemeProvider, defaultTheme } from "sancho";
 
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";

import Customer from './components/Customer'
import Logo from './shared/Logo'

const theme = {
  ...defaultTheme,
  fonts: {
    ...defaultTheme.fonts,
    sans: "Mulish, sans-serif",
    base: "Mulish, sans-serif"
  }
};

function App(props) {
  return (
    <div className={styles.wrapper}>
      <ThemeProvider theme={theme}>
        <Container>
          <Router>
            <Switch>
              <Route path={`/customer/:customerId`}>
                <Customer config={props.config} />
              </Route>
              <Route path="/">
                <div style={{margin: "50px auto 0", textAlign: "center" }}>Loading ...</div>
              </Route>
            </Switch>
          </Router>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default App;
