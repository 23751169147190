import React, { useRef, useEffect } from 'react';
import {
    useRouteMatch,
    useParams,
    Link,
} from "react-router-dom";

import { Badge, Text, Layer } from 'sancho'
import { Embed } from 'sancho'

import { Compliance, Impact } from "../shared/helpers"
import Statistics from "../shared/Statistics"


import styles from './PageThumbnail.module.css';

function PageThumbnail(props) {
    const [show, setShow] = React.useState(false);
    let match = useRouteMatch();
  
    return (
  
      <Layer elevation="sm" className={styles.page_link}>
          <Link to={`${match.url}/${props.page.hashid}`} className={styles.page}>
              <div
              className={styles.image}
                style={{
                  backgroundImage: `url(${props.page.image})`,
                }}
              />
              <div className={styles.inlineWrapper}>
            <Text variant="uppercase">{props.page.title} </Text>
            <Text variant="h5">{props.page.score}%</Text>
            <Statistics data={{low: props.page.low, high: props.page.high, strengths: props.page.strengths}} compact />
            <Text variant="subtitle">{props.page.annotations_count} annotations</Text>
            </div>
        </Link>
          </Layer>
  
    );
  }
  
  export default PageThumbnail