import React, { useState } from 'react';
import styles from './AnnotationBrowser.module.css';
import Annotation from '../shared/Annotation'
import AnnotationBullet from '../shared/AnnotationBullet'
import { Tabs, Tab, Pager, TabPanel, Text } from 'sancho'

function AnnotationBrowser(props) {

    const [annotationOpened, setAnnotationOpened] = useState(0);
    const [annotationOpenedSource, setAnnotationOpenedSource] = useState(false);
    const [index, setIndex] = React.useState(0);
  
    const handleClick = (annotationId, source) => {
      setAnnotationOpened(annotationId);
      setAnnotationOpenedSource(source)
    }

    // var tabs = [
    //   {key: 'all'},
    //   {key: 'low'},
    //   {key: 'hogh'},
    //   {key: 'excellent'}

    // ]

    var filterItems = () => {
      switch (index) {
        case 0:
          return props.annotations
          break;
      
        case 1:
          return props.annotations.filter(item => item.compliance < 3)
          break;
      
        case 2:
          return props.annotations.filter(item => item.compliance > 2 && item.compliance < 5)
          break;
      
        case 3:
          return props.annotations.filter(item => item.compliance == 5)
          break;
      
        case 4:
          return props.annotations.filter(item => item.location_x == null)
          break;
      
        default:
          return props.annotations
          break;
      }
    }

    let items = filterItems();
  
    return (
      <div className={styles.wrapper}>
        <div className={styles.image_container}>
          <div className={styles.bullet_wrapper}>
          {items.map((annotation, i) => {
            if(!annotation.location_x && !annotation.location_y) return null;
            return <AnnotationBullet 
            key={annotation.id} 
            annotation={annotation} 
            handleClick={handleClick}
            open={annotation.id == annotationOpened ? true : false}
            openSource={annotationOpenedSource} 
               />
          })}
          <img src={props.image} className={styles.image} />
  
          </div>
        </div>
        <div className={styles.annotations}>
          
          <Tabs value={index} onChange={i => setIndex(i)} className={styles.tabs}>
              <Tab id="1">All</Tab>
              <Tab id="2">Low</Tab>
              <Tab id="3">High</Tab>
              <Tab id="4">Excellent</Tab>
              <Tab id="5">Whole page</Tab>
          </Tabs>



          <div className={styles.annotations_wrapper}>
            {items.map((annotation, i) => <Annotation 
                key={annotation.id} 
                annotation={annotation} 
                handleClick={handleClick}
                open={annotation.id == annotationOpened ? true : false}
                openSource={annotationOpenedSource} 
                display_as_group
                  />)}
          </div>
        </div>
      </div>
    )
  }


export default AnnotationBrowser