
import React from 'react';


import { Text, Divider, Spinner, Button, TableBody } from 'sancho'
import { Navbar, Toolbar } from 'sancho'
import { IconCheckCircle, IconArrowRight } from 'sancho'
import { Table, TableHead, TableRow, TableCell } from 'sancho'

import Statistics from '../shared/Statistics'
import Opportunity from '../shared/Opportunity'
import PageThumbnail from '../shared/PageThumbnail'
import GroupThumbnail from '../shared/GroupThumbnail'
import Logo from '../shared/Logo'
import {Score} from '../shared/helpers'

import styles from './Overview.module.css';


function Overview(props) {
    return (
      <div>
  
        <Navbar css={{
                  flex: "0 0 auto",
                }}>
        <Toolbar css={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px 0"
                  }}>
         <div>
          <Logo logo={props.data.partner.logo} name={props.data.partner.name} />
            
             </div>
         <div>
  
         {props.data.partner.show_about_link ? 
          <Button variant="ghost" component="a" href="https://www.unicornaudit.com/?utm_campaign=app&utm_medium=link&utm_source=app&utm_content=link " iconAfter={<IconArrowRight />}>
          About us
            </Button> : null }
        </div>
        </Toolbar>
      </Navbar>
  
      <Text variant="display3">{props.data.title}</Text>
      <Divider />
  
  
  
        <div className={styles.intro}>
        <div className={styles.metric}>
          <Text variant="subtitle">Experience score</Text>
          <Text variant="display1"><Score score={props.data.score} /></Text>
        </div>
        <div className={styles.comment}>
          {/* <Text variant="subtitle">Comment from {props.data.consultant.name}</Text> */}
          <Text variant="paragraph">{props.data.comment ? props.data.comment : "Please see report below"}</Text>
          <Statistics data={props.data} />
        </div>
      </div>
       
        <Text variant="h2" className={styles.header}>Site pages</Text>
        <div className={styles.pages}>
          {props.data.pages.map(page => <PageThumbnail key={page.hashid} page={page} customerId={props.customerId} />)}
        </div>
      
      <Text variant="h2" className={styles.header}>Summary</Text>
      <div className={styles.groups}>
        {props.data.groups.map(group => <GroupThumbnail key={group.id} group={group} />)}
      </div>
  
  
  
  
        <Text variant="h2" className={styles.header}>Opportunities</Text>
  
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Room for improvement</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Guideline</TableCell>
              <TableCell>Page</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.opportunities.map(o => <Opportunity key={o.id} opportunity={o} />)}
          </TableBody>
        </Table>
        {/* <div className={styles.opportunities}> */}
        {/* </div> */}
      </div>
    )
  }
  
  export default Overview