import React from 'react';

import { Text } from 'sancho'

import { IconPlusCircle, IconMinusCircle, IconAward, IconCheckCircle, IconMonitor, IconFlag } from 'sancho'

import styles from './Statistics.module.css';

function Statistics(props) {
    return (
      <div className={props.className}>
        <div className={styles.statistics}>
        {props.data.score && !props.data.pages ? <div className={styles.statistic}>
            <Text variant={props.compact ? "paragraph" : "h4"}>{props.data.score}%</Text>
            {props.compact ? null : <Text variant="subtitle">Score</Text>}
          </div> : null }
          <div className={styles.statistic}>
            <Text variant={props.compact ? "paragraph" : "h4"}>{props.data.low} <IconMinusCircle className={styles.low} /></Text>
            {props.compact ? null : <Text variant="subtitle">Rated low</Text>}
          </div>
          <div className={styles.statistic}>
            <Text variant={props.compact ? "paragraph" : "h4"}>{props.data.high} <IconPlusCircle className={styles.high}  /></Text>
            {props.compact ? null : <Text variant="subtitle">Rated high</Text>}
          </div>
          <div className={styles.statistic}>
            <Text variant={props.compact ? "paragraph" : "h4"}>{props.data.strengths} <IconAward className={styles.strength}  /></Text>
            {props.compact ? null : <Text variant="subtitle">Excellent</Text>}
          </div>
          {props.data.num_annotations ? <div className={styles.statistic}>
            <Text variant={props.compact ? "paragraph" : "h4"}>{props.data.num_annotations} <IconCheckCircle /></Text>
            {props.compact ? null : <Text variant="subtitle">Total factors analysed</Text>}
          </div> : null }
          {props.data.num_annotations && props.data.pages ? <div className={styles.statistic}>
            <Text variant={props.compact ? "paragraph" : "h4"}>{props.data.pages.length} <IconMonitor /></Text>
            {props.compact ? null : <Text variant="subtitle">Pages</Text>}
          </div> : null }
          {props.data.opportunities ? <div className={styles.statistic}>
            <Text variant={props.compact ? "paragraph" : "h4"}>{props.data.opportunities.length} <IconFlag /></Text>
            {props.compact ? null : <Text variant="subtitle">Opportunities</Text>}
          </div> : null }

        </div>
      </div>
  
    )
  }
  
  export default Statistics