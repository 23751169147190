import React from 'react';
import {
    useRouteMatch,
    useParams,
    Switch,
    Route,
    Link,
} from "react-router-dom";
import useSWR from 'swr'

import SubgroupAnnotations from './SubgroupAnnotations'


import { List, ListItem } from 'sancho'
import { Text, Divider, Spinner, Button } from 'sancho'
import { Navbar, Toolbar, Alert } from 'sancho'
import { IconChevronRight, IconArrowLeft } from 'sancho'

import Statistics from '../shared/Statistics'
import styles from './GroupBrowser.module.css';

import { fetcher } from "../shared/helpers"

function Group(props) {
    let match = useRouteMatch();
    let { customerId, groupId, subgroupId } = useParams();
    const { data, error } = useSWR(`${props.config.API_BASE_URL}/audit/api/customers/${customerId}/group/${groupId}`, fetcher)
   
    if (error) return <div style={{margin: '30px 0 0 0'}}><Alert intent="danger" title="Data failed to load. Please try again." /></div>
    if (!data) return <div style={{margin: '30px 0 0 0'}}><Spinner label="Loading..." center /></div>
  
  
    return (
      <div>
  
        <Navbar css={{
                  flex: "0 0 auto",
                }}>
        <Toolbar css={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px 0"
                  }}>
         <div>
         <Button variant="ghost" component={Link} to={`/customer/${customerId}`} iconBefore={<IconArrowLeft/>}>
          Back
            </Button>
            
             </div>
         <div>
  
  
  
        </div>
        </Toolbar>
      </Navbar>
  
      <Text variant="display3">{data.title}</Text>
      <Divider />
  
  
      <div className={styles.wrapper}>
        <div className={styles.list}>
                  
        <List>  
      {data.subgroups.map(subgroup => {return(
            <ListItem
            primary={<Text variant="uppercase">{subgroup.title}</Text>}
            secondary={<Statistics data={{low: subgroup.low, high: subgroup.high, strengths: subgroup.strengths}} compact />}
            component={Link}
            to={`${match.url}/${subgroup.id}`}
            contentAfter={<IconChevronRight />}
          />
      )})}
  </List>
  
        </div>
        <div className={styles.annotations}>
            <Switch>
              <Route path={`${match.path}/:subgroupId`}>
  
                <SubgroupAnnotations annotations={data.annotations}  />
  
              </Route>
  
  
              <Route>
                <Text variant="paragraph" css={{textAlign: "center"}}>Select subgroup to see annotations</Text>
              </Route>
  
            </Switch>
  
        </div>
      </div>
  
  
  
  
      </div>
    )
  }
  
  export default Group