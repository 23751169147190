import React from 'react';
import {
    useRouteMatch,
    useParams,
    Switch,
    Route,
} from "react-router-dom";
import AnnotationPreview from './AnnotationPreview'
import Annotation from '../shared/Annotation'

import styles from './SubgroupAnnotations.module.css';

import { Text } from "sancho"

function SubgroupAnnotations(props) {
    let match = useRouteMatch();
    let { customerId, groupId, subgroupId, annotationId } = useParams();
    console.log(useParams())
    return (
      <>
  
  <div className={styles.wrapper}>
        <div className={styles.list}>
  
  
  
        {props.annotations.filter((annotation) => {return annotation.guideline.subgroup.id == subgroupId}).map((annotation, i) => <Annotation 
        key={annotation.id} 
        annotation={annotation} 
        to={`${match.url}/${annotation.id}`}
        open={annotation.id == annotationId ? true : false}
        display_as_page
        // open={annotation.id == annotationOpened ? true : false}
        // openSource={annotationOpenedSource} 
          />)}
        </div>
        <div className={styles.annotations}>
        <div className={styles.annotations_container}>
  
  
          <Switch>
              <Route path={`${match.path}/:annotationId`}>
  
                <AnnotationPreview annotations={props.annotations} />
  
              </Route>

                
              <Route>
                <Text variant="paragraph" css={{textAlign: "center"}}>Open annotation to see related page</Text>
              </Route>
  
            </Switch>
        </div>
        </div>
      </div>
            </>
  
    )
  }
  
  export default SubgroupAnnotations