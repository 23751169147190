import React, { useRef, useEffect, useState } from 'react';
import {
    useRouteMatch,
    useParams,
    useLocation,
    Link,
} from "react-router-dom";

import { Badge, Button, Layer } from 'sancho'
import { Collapse, useCollapse } from 'sancho'
import { Tooltip } from 'sancho'
import Linkify from 'react-linkify';
import styles from './Annotation.module.css';
import { Compliance, Impact } from "../shared/helpers"

function ComplianceBadge(props) {

  let className = (compliance) => {
    switch(compliance) {
      case 1: return styles.badge_low;
      case 2: return styles.badge_low;
      case 3: return styles.badge_high;
      case 4: return styles.badge_high;
      case 5: return styles.badge_strength;
      default: return styles.badge_low;
    }
  }  


  return <Badge className={className(props.compliance)}><Compliance value={props.compliance} suffix="" /> </Badge>
}


function Annotation(props) {

  const [show, setShow] = useState(true);

  let location = useLocation();

  let opened_from_link = location.pathname.split("/").slice(-1) == props.annotation.id;




    let onclick = (e) => {
      if(props.handleClick) {
        props.handleClick(props.annotation.id, 'annotation'); 
      }
    }
  
    let ref = useRef({})
  
    useEffect(() => {
      if(props.open && props.openSource == 'bullet') {
        ref.current.scrollIntoView({behavior: "smooth", block: "nearest"})
      }
      setShow(props.open)

    }, [props.open])
  
    let className = () => {
      let className = `${styles.annotation}`
      if(props.open || opened_from_link) {
        className = `${className} ${styles.annotation_active}`
      } 
      // if(props.to) {
      //   className = `${className} ${styles.is_link}`
      // } 
      // if(props.annotation.location_x && props.annotation.location_y) {
      //   className = `${className} ${styles.has_location}`
      // } 
      // console.log(className)
      return className;
    }
  

    const content = <Layer elevation="xs" className={className()} onClick={onclick} ref={ref}>

      <div className={styles.wrapper}>
      <div className={styles.meta}>
        {props.display_as_group ? <div className={''}>{props.annotation.guideline.subgroup.group.title}, {props.annotation.guideline.subgroup.title}</div> : null}
        {props.display_as_page ? <div className={''}>{props.annotation.page.title} on {props.annotation.page.device}</div> : null}
        <div className={styles.id}>{props.annotation.id}</div>
      </div>
      
      <h3 className={styles.title}>{props.annotation.guideline.title}</h3>
      <p>
        <ComplianceBadge compliance={props.annotation.compliance} />
        {props.annotation.guideline.impact == 3 ? <Badge className={styles.badge}><Impact value={props.annotation.guideline.impact} suffix="impact" /> </Badge> : null}
        {!props.annotation.location_x && !props.annotation.location_y ? <Badge className={styles.whole_page_badge}>Whole page</Badge> : null}
      </p>
      
  

      
      {props.annotation.comment ? <blockquote className={styles.comment}><small className={styles.author}>Comment from consultant</small> <Linkify>{props.annotation.comment}</Linkify></blockquote> : null}
  
      </div>

        {/* {props.annotation.guideline.comment && <Button size="xs" variant="outline" {...state.buttonProps}>Read more</Button>} */}
        
        { (show || opened_from_link) && props.annotation.guideline.comment ?
          <div className={styles.actions}>
              <div className={styles.guideline}>
              <Linkify>{props.annotation.guideline.comment}</Linkify>
          </div>
          </div> : null }

      {/* <p><Compliance value={props.annotation.compliance} /></p> */}
      {/* <Impact value={props.annotation.guideline.impact} /> impact */}
      </Layer>
  
    return props.to ? <Link to={props.to} className={styles.annotation_link} >{content}</Link> : content
      

   
  }

  export default Annotation