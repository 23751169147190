import React, { useRef, useEffect } from 'react';
import {
    useRouteMatch,
    useParams,
    Link,
} from "react-router-dom";

import { Badge, Text, Layer } from 'sancho'
import { Embed } from 'sancho'

import { Compliance, Impact } from "../shared/helpers"
import Statistics from "../shared/Statistics"
import { TableRow, TableCell } from 'sancho'


import styles from './Opportunity.module.css';


function Opportunity(props) {
  let match = useRouteMatch();


    return (
      
          <TableRow elevation="sm" className={styles.opportunity_link}>

            <TableCell><Impact value={(props.opportunity.gap-3)/3} /></TableCell>
            <TableCell>{props.opportunity.guideline.subgroup.title}</TableCell>
            <TableCell>
              <p>{props.opportunity.guideline.title} </p>
            </TableCell>
            <TableCell>
            <Link 
         to={`${match.url}/group/${props.opportunity.guideline.subgroup.group.id}/${props.opportunity.guideline.subgroup.id}/${props.opportunity.id}`}
         className={styles.opportunity}>View page</Link>
            </TableCell>
  
            {/* <TableCell variant="subtitle">Room for improvement </TableCell> */}
        {/* </Link> */}
          {/* <Layer elevation="sm" className={styles.opportunity_link}>
        <Link 
         to={`${match.url}/group/${props.opportunity.guideline.subgroup.group.id}/${props.opportunity.guideline.subgroup.id}/${props.opportunity.id}`}
         className={styles.opportunity}>
            <Text variant="uppercase">{props.opportunity.guideline.subgroup.title}</Text>
            <Text variant="paragraph">{props.opportunity.guideline.title} </Text>
  
            <Text variant="subtitle">Room for improvement </Text>
            <Text variant="paragraph"><Impact value={(props.opportunity.gap-3)/3} /></Text>
        </Link>
          </Layer> */}
          </TableRow>

    )
  }
  
  export default Opportunity