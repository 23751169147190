import React, { useRef, useEffect } from 'react';

import { IconPlusCircle, IconMinusCircle, IconAward } from 'sancho'


import styles from './AnnotationBullet.module.css';
import { compliance_display } from './helpers';



function AnnotationBullet(props) {
    const top = props.annotation.location_y * 100;
    const left = props.annotation.location_x * 100;
  
    let ref = useRef({})
  
    useEffect(() => {
      if(props.open && top && left && props.openSource == 'annotation') {
        ref.current.scrollIntoView({behavior: "smooth", block: "nearest"}) //behavior: "smooth",  // https://stackoverflow.com/questions/49318497/google-chrome-simultaneously-smooth-scrollintoview-with-more-elements-doesn
        console.log('scroll bullet into view', props.annotation.id, top, left)
      }
    }, [props.open])
  
    let c = (compliance) => {
      switch(compliance) {
          case 1: return styles.low;
          case 2: return styles.low;
          case 3: return styles.high;
        case 4: return styles.high;
        case 5: return styles.strength;
        default: return styles.low;
      }
    }  

    let icon = (compliance) => {
      switch(compliance) {
        case 1: return <IconMinusCircle />;
        case 2: return <IconMinusCircle />;
        case 3: return <IconPlusCircle />;
        case 4: return <IconPlusCircle />;
        case 5: return <IconAward className={styles.award} />;
        default: return '';
      }
    }
  
    let onclick = (e) => {
      if(props.handleClick) {
        props.handleClick(props.annotation.id, 'bullet'); 
      }
    }
  
    let className = () => {
      if(props.open) {
        return `${styles.bullet} ${c(props.annotation.compliance)} ${styles.bullet_active}`
      } else {
  
        return `${styles.bullet} ${c(props.annotation.compliance)}`
      }
    }
  
    return (
      <div className={className()} style={{top: `${top}%`, left: `${left}%`}} onClick={onclick} ref={ref}>{icon(props.annotation.compliance)}</div>
    )
  }
  
  export default AnnotationBullet